import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Products from '@/views/Products.vue'
import Contact from '@/views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: 'Windsor Quality Pourty | Home'}
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {title: 'Windsor Quality Pourty | Products'}
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {title: 'Windsor Quality Pourty | Contact Us'}
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'Windsor Quality Poultry';
  });
})

export default router
