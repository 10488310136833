<template>
  <div>
    <v-card-title ref="title"><h2 class="my-2" style="color:#0000fe;"><i>
      Welcome to <br class="d-flex d-sm-none d-md-none" />Windsor Quality Poultry
    </i></h2></v-card-title>

    <v-card-text class='text-body-1 black--text'>
      Windsor Quality Poultry offers quality service to the Windsor Area and have started to branch out to the county area.
    </v-card-text>

    <v-row>
      <v-col cols="12" md="6">
      <v-card-subtitle class='subtitle-1 font-weight-bold black--text'>
        <i>Delivery to surrounding areas is as follows:</i>
      </v-card-subtitle>
      <v-simple-table dense>
        <tbody>
          <tr>
            <td> </td>
            <td class='text-body-1'>Windsor</td>
            <td class="text-body-1">Monday - Saturday</td>
          </tr><tr>
            <td> </td>
            <td class='text-body-1'>LaSalle</td>
            <td class="text-body-1">Monday - Saturday</td>
          </tr><tr>
            <td> </td>
            <td class='text-body-1'>Essex</td>
            <td class="text-body-1">Tuesday & Thursday</td>
          </tr><tr>
            <td> </td>
            <td class='text-body-1'>Amherstburg</td>
            <td class="text-body-1">Wednesday & Friday</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-text class='text-body-1 black--text'>
        Quality personal service is only a phone call away. The cut off time for same day delivery is 9:00 am for cut products and 10:00 am for boxed product. After that time it will be delivered next day.
      </v-card-text>
      </v-col>
      <v-col cols="12" md="6">
        <v-img
          alt="Roast Chicken"
          class="ma-0 pa-0"
          max-height="300"
          contain
          :src="require('@/assets/roast chicken.jpg')"
        />
      </v-col>
    </v-row>
    <v-card-text class='text-body-1 black--text'> 
      We would love to hear from you. For more information or to talk to our staff, please call us at <a href='tel:5192501991'>519-250-1991</a>.
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    if (this.$route.params.title_scroll) {
      window.scroll({
        top: this.$refs.title.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
