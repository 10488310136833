<template>
  <div>
    <v-card-title ref="title"><h2 class="my-2" style="color:#0000fe;"><i>
      Products
    </i></h2></v-card-title>

    <v-row>
      <v-col cols="12" md="7" :order="$vuetify.breakpoint.mdAndUp ? '2' : '1'">
        <v-img
          alt="Grilled Chicken Drumsticks"
          class="ma-0 pa-0"
          contain
          :src="require('@/assets/Grilled_chicken_drumsticks.jpg')"
        />
      </v-col>
      <v-col cols="12" md="5" :order="$vuetify.breakpoint.mdAndUp ? '1' : '2'">

        <v-card-subtitle class='subtitle-1 font-weight-bold black--text'>
          <i>Please See the Different Cuts we Have to Offer:</i>
        </v-card-subtitle>

        <v-card-text class='text-body-1 black--text'>
          <h4>Roaster</h4>
          <ul>
            <li
              v-for="(item, index) in roaster"
              :key="index"
            >
              {{item}}
            </li>
          </ul>

          <br />
          
          <h4>Fryers</h4>
          <ul>
            <li
              v-for="(item, index) in fryers"
              :key="index"
            >
              {{item}}
            </li>
          </ul>
        </v-card-text>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data: () => ({
    roaster: [
      'Roaster Fronts',
      'Roaster Whole Wings',
      'Roaster S&T Wings',
      'Roaster B/L S/L',
      'Roaster B/L S/O',
      'Roaster Bone In',
      'Roaster B/A',
      'Legs & Thighs Knuckle Off',
      'B/L Thighs',
      'Roaster Kabobs',
    ],
    fryers: [
      'Fryer B/L S/L',
      'Fryer B/L S/L Portion',
      'Fryer B/L S/O Portion',
      'Fryer Supreme',
      'Fryer Bone In Breast',
      'Fryer B/A Legs',
      'Drumsticks',
      'Thighs',
      'Knife Cut Thigh',
      'Chicken Kabobs',
      'Chicken Shawarma',
    ]
  }),
  mounted() {
    if (this.$route.params.title_scroll) {
      window.scroll({
        top: this.$refs.title.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
