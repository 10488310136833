<template>
  <v-app>
    <Header />
    <v-main>
      <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="pt-1">
          <Carousel />
          <router-view />
          <SubFooter />
          </v-card> 
        </v-col>
      </v-row></v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue"
import Carousel from "@/components/Carousel.vue"
import SubFooter from "@/components/SubFooter.vue"
import Footer from "@/components/Footer.vue"
export default {
  name: 'App',
  components: {Header, Carousel, SubFooter, Footer},
  data: () => ({
    //
  }),
};
</script>
