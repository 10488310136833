<template>
  <div>
    <v-card-title ref="title"><h2 class="my-2" style="color:#0000fe;"><i>
      Contact Us
    </i></h2></v-card-title>

    <v-row class="ml-2">
      <v-col cols="12" md="7" order="2">
        <v-img
          alt="Barbecue Chicken Wings"
          class="ma-0 pa-0"
          contain
          :src="require('@/assets/Barbecue_chicken_wings-1000x667.jpg')"
        />
      </v-col>
      <v-col cols="12" md="5" order="1">

        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field
            v-model="name"
            label="Name"
            prepend-icon="mdi-account"
            outlined
            :readonly="saved || saving"
          ></v-text-field>

          <v-text-field
            v-model="phone"
            label="Phone Number"
            prepend-icon="mdi-phone"
            outlined
            :readonly="saved || saving"
          ></v-text-field>

          <v-text-field
            v-model="email"
            label="E-mail"
            prepend-icon="mdi-email"
            outlined
            :readonly="saved || saving"
          ></v-text-field>

          <v-textarea
            v-model="questions"
            label="Questions"
            prepend-icon="mdi-message-question-outline"
            outlined
            :readonly="saved || saving"
          ></v-textarea>

          <v-btn
            :color="saved ? 'success' : 'primary'"
            class="mb-4"
            @click="submit"
            :loading="saving"
          >
            <v-icon v-if="saved" class="mr-2">mdi-check</v-icon>
            {{saved ? 'Submitted' : 'Submit Questions' }}
          </v-btn>

          <v-snackbar
            v-model="save_dialog"
            :timeout="6000"
          >
            <v-alert type="error">Error</v-alert>
            {{save_response}}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="site_down = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

        </v-form>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Contact',
  data: () => ({
    name: null,
    phone: null,
    email: null,
    questions: null,

    saved: false,
    saving: false,

    save_dialog: false,
    save_response: '',
  }),
  methods: {
    submit() {
      if (this.saved || this.saving) { return null }
      this.saving = true
      axios
        .post(
          process.env.VUE_APP_API_URL + '/contact_us',
          {name: this.name, phone: this.phone, email: this.email, questions: this.questions})
        .then(({ data }) => {
          this.saving = false
          if (data.submitted) {
            this.saved = true
          } else {
            this.save_dialog = true
            this.saved = true
            this.save_response = 'Your questions could not be submitted, please try back later'
          }
        })
        .catch(err =>{
          this.saving = false
          this.save_dialog = true
          this.save_response = 'Your questions could not be submitted, please try again later'
          console.log(err)
        })
    }
  },
  mounted() {
    if (this.$route.params.title_scroll) {
      window.scroll({
        top: this.$refs.title.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
