<template>
  <v-card class="ma-5">
    <v-carousel
      cycle 
      height="auto"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
      >
        <v-img
          :src="item.src"
          aspect-ratio="2.14"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
  export default {
    name: 'Carousel',
    data () { return {
      items: [
        { src: require('@/assets/banner-1.jpg') },
        { src: require('@/assets/banner-2.jpg') },
        { src: require('@/assets/banner-3.jpg') },
      ]
    }},
  }
</script>