<template>
  <v-app-bar
    app
    color="#ffff00"
    height="155px"
  >
    <div class="d-flex align-center">
      <v-img
        class="mt-4"
        alt="Windsor Quality Poultry"
        contain
        :src="require('@/assets/WQP Logo.png')"
        width="220"
      />
    </div>

    <v-spacer></v-spacer>

    <span 
      class="mr-7 mt-4 text-center text-md-h4 text-h6 text-uppercase"
      style="color:#0000fe;"
      text-center
    >
      Don’t hesitate<br>
      to give us a call!<br/>
      <b>519-250-1991</b>
    </span>

    <template v-slot:extension>
      <v-tabs fixed-tabs>
        <v-tab style="color:#0000fe;" :to="{name: 'Home', params: {title_scroll: true}}">Home</v-tab>
        <v-tab style="color:#0000fe;" :to="{name: 'Products', params: {title_scroll: true}}">Products</v-tab>
        <v-tab style="color:#0000fe;" :to="{name: 'Contact', params: {title_scroll: true}}">Contact Us</v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Header'
  }
</script>