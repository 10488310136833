<template>
  <div>
    <v-card height="5px" class="my-2" style="background-color: #0000fe;"></v-card>

    <v-row>
      <v-col cols="12" md="5" lg='4'>
        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-btn
                icon
                color="indigo"
                href="tel:5192501991"
              ><v-icon>
                mdi-phone
              </v-icon></v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>(519) 250-1991</v-list-item-title>
              <v-list-item-subtitle>Phone</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-btn
                icon
                color="indigo"
              ><v-icon>
                mdi-fax
              </v-icon></v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>(519) 250-1322</v-list-item-title>
              <v-list-item-subtitle>Fax</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-btn
                icon
                color="indigo"
                href="mailto:info@windsorqualitypoultry.ca"
              ><v-icon>
                mdi-email
              </v-icon></v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>info@windsorqualitypoultry.ca</v-list-item-title>
              <v-list-item-subtitle>Email</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-btn
                icon
                color="indigo"
                href="https://goo.gl/maps/Yy3yj2N4XazDJ8HdA"
                target="_blank"
              ><v-icon>
                mdi-map-marker
              </v-icon></v-btn>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>7095 Manning Rd</v-list-item-title>
              <v-list-item-subtitle>Maidstone, ON &nbsp;N0R 1K0</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <iframe 
          class="mt-6"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11811.811107437117!2d-82.962374!3d42.258181!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc2d86cf7fa76f1cd!2sCNC+Trading+Co!5e0!3m2!1sen!2sca!4v1507228414156" 
          height="300" 
          width="95%"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </v-col>
    </v-row>

    <v-card height="5px" class="mt-2" style="background-color: #0000fe;"></v-card>
    <br/><!--br class="d-none d-md-flex"/-->

  </div>
</template>

<script>
  export default {
    name: 'SubFooter',
  }
</script>
